import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import FormPage from "../components/JobPosting/FormPage";

const EmploymentPost = ({ data, location }) => {

  const {
    allStrapiEmployments: { nodes: jobPostingHeaderInstr },
  } = data;

  console.log(jobPostingHeaderInstr);

  return (
    <Layout location={location} crumbLabel="EmploymentPost">
      <div className="box "  >
        <FormPage jobPostingHeaderInstr={jobPostingHeaderInstr}  />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiEmployments {
      nodes {
        jp_emp_instr
        jp_emp_title
      }
    }
  }
`

export default EmploymentPost;