import React, { useEffect, useState } from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBAlert,
  toast,
} from 'mdbreact'
import ReactMarkdown from 'react-markdown'
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha'
import emailjs from "emailjs-com";
import swal from 'sweetalert2' //sb added
import axios from 'axios'
import { AXIOSLINK } from '../../utils/constants'
import '../button.css'

const FormPage = ({ jobPostingHeaderInstr }) => {
  const [positionTitle, setPositionTitle] = useState('')
  const [applDeadLine, setApplDeadLine] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [location, setLocation] = useState('')
  const [positionDesc, setPositionDesc] = useState('')
  const [positionQual, setPositionQual] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [contactFax, setContactFax] = useState('')
  const [contactVideoPhone, setContactVideoPhone] = useState('')
  const [contactOthers, setContactOthers] = useState('')
  const [userCaptchaText, setUserCaptchaText] = useState('')
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)
  const [captchaErrorMsg, setCaptchaErrorMsg] = useState('')
  const [startDateErrorMessage, setStartDateErrorMessage] = useState('')
  const [applDeadLineErrorMessage, setApplDeadLineErrorMessage] = useState('')
  const [isJobPosted, setIsJobPosted] = useState(false)

  const [cPhoneErr, setcPhoneErr] = useState('')
  const [cFaxErr, setcFaxErr] = useState('')

  const handlePositionTitle = event => setPositionTitle(event.target.value)
  //const handleApplDeadLine = event => setApplDeadLine(event.target.value)

  const handleApplDeadLine = event => {
    let dateFromForm = event.target.value
    const date = new Date().setHours(0,0,0,0);
    const d2 = new Date(dateFromForm).setHours(0,0,0,0);
   // const stDate = new Date(startDate).setHours(0,0,0,0);
    if(d2 < date)
    {
      setApplDeadLineErrorMessage(
        'Invalid Input. Please choose the future date.'
      )
      setApplDeadLine("");
    }
    
  
    
    else {
      setApplDeadLine(event.target.value)
      setApplDeadLineErrorMessage('')
    } 
  }

  const showErr = (e, type) => {
    if (e.key !== ' ') {
      if (type === 'phone') return setcPhoneErr('')
      if (type === 'fax') return setcFaxErr('')
    }
    e.preventDefault()
    if (type === 'phone') setcPhoneErr('Spaces not allowed for Contact Phone.')
    if (type === 'fax') setcFaxErr('Spaces not allowed for Contact Fax.')
  }

  const handleContactPhone = event => {
    if (event.target.value.length > 16)
      return setcPhoneErr('Contact phone no length exceeds..')
    setContactPhone(event.target.value)
    setcPhoneErr('')
  }
  const handleContactFax = event => {
    setContactFax(event.target.value)
  }

  const handleStartDate = event => {
    let dateFromForm = event.target.value
    const date = new Date().setHours(0,0,0,0)
    const d2 = new Date(dateFromForm).setHours(0,0,0,0)
   // const deadlineDate = new Date(applDeadLine).setHours(0,0,0,0)
    if(d2 < date)
    {
      setStartDateErrorMessage(
        'Invalid Input. Please choose the future date.'
      )
      setStartDate("");
    }
   
    else {
      setStartDate(event.target.value)
      setStartDateErrorMessage('')
    } 
  }
  const handleLocation = event => setLocation(event.target.value)
  const handlePositionDesc = event => setPositionDesc(event.target.value)
  const handlePositionQual = event => setPositionQual(event.target.value)
  const handleContactName = event => setContactName(event.target.value)
  const handleContactEmail = event => setContactEmail(event.target.value)
  // const handleContactPhone = event => setContactPhone(event.target.value)

  const handleContactVideoPhone = event =>
    setContactVideoPhone(event.target.value)
  const handleContactOthers = event => setContactOthers(event.target.value)
  const handleUserCaptchaText = event => {
    setUserCaptchaText(event.target.value)
    setCaptchaErrorMsg('')
  }
  useEffect(() => {
    loadCaptchaEnginge(6, 'red', 'black')
    toast.success('Success message', {
      closeButton: false,
    })
  }, [])
  const captchaReload = () => {
    loadCaptchaEnginge(6, 'red', 'black')
    setUserCaptchaText('')
  }

  const handleSubmit = event => {
    event.preventDefault()
    setIsJobPosted(true)

    if (userCaptchaText && userCaptchaText.length > 5) {
      if (validateCaptcha(userCaptchaText, false) == true) {
        console.log('captcha verified')
        setIsCaptchaVerified(true)
        setCaptchaErrorMsg('Captcha Verified Successfully!!!')
        makeApiCall()
        setUserCaptchaText('')

        captchaReload()
      } else {
        setIsCaptchaVerified(false)
        setUserCaptchaText('')
        setCaptchaErrorMsg('Incorrect Captcha!!! Please enter valid Captcha!!!')
        captchaReload()
      }
    } else {
      setIsCaptchaVerified(false)
      setUserCaptchaText('')
      setCaptchaErrorMsg('Incorrect Captcha!!! Please enter valid Captcha!!!')
      captchaReload()
    }

    if (isCaptchaVerified) {
    }
  }

  function defaulyApiCall() {
    const data = {
      jp_position_title: positionTitle,
      jp_appl_deadline: applDeadLine,
      jp_start_date: startDate,
      jp_location: location,
      jp_position_desc: positionDesc,
      jp_position_qual: positionQual,
      jp_contact_name: contactName,
      jp_contact_email: contactEmail,
      jp_contact_phone: contactPhone,
      jp_contact_fax: contactFax,
      jp_contact_videophone: contactVideoPhone,
      jp_contact_other: contactOthers,
      published_at: null,
    }
    axios
      .post(AXIOSLINK +'jobs', data)
      .then(response => {
        if (response.status == 200) {
          swal.fire({
            title: 'Job Posted!',
            text:
              'Job Posted Successfully, will proccess as soon as possible..',
            icon: 'success',
            confirmButtonText: 'Okay!',
            confirmButtonColor: '#00c851',
          })
          setIsJobPosted(true)
          resetForms()
        } else {
          setIsJobPosted(false)
        }
      })
      .catch(err => {
        // debugger
        console.log({ err })
        console.log('====================================')
        console.log(`error creating Page:${err}`)
        console.log('====================================')
        //   reject(new Error(`error on page creation:\n${err}`))

        swal.fire({
          title: 'Job Failed!',
          text: 'Job Failed.. Please try again later... ',
          icon: 'error',
          confirmButtonText: 'Okay!',
          confirmButtonColor: '#00c851',
        })
      })
  }

  function makeApiCall() {
    //sb added
    swal
      .fire({
        title: 'Are you sure to Post?',
        icon: 'warning',
        confirmButtonText: 'Procced',
        confirmButtonColor: '#008000',
        showCancelButton: true,
      })
      .then(res => {
      
        var templateParams = {
            Title : positionTitle,
          }

        emailjs.send('service_oum2d4o', 'template_q5bvsrn', templateParams, 'user_V2TjOGkvC6BcqaTAwfAjP')
          .then(
            function(result) {
              
              //console.log('SUCCESS!', result.status, result.text);
            },
            function(error) {
              //console.log('FAILED...', error);
            }
          )
      
        console.log('====================================')
        console.log(res.dismiss)
        console.log('====================================')
        if (!res.isConfirmed) return
        const data = {
          jp_position_title: positionTitle,
          jp_appl_deadline: applDeadLine,
          jp_start_date: startDate,
          jp_location: location,
          jp_position_desc: positionDesc,
          jp_position_qual: positionQual,
          jp_contact_name: contactName,
          jp_contact_email: contactEmail,
          jp_contact_phone: contactPhone,
          jp_contact_fax: contactFax,
          jp_contact_videophone: contactVideoPhone,
          jp_contact_other: contactOthers,
          published_at: null,
        }
        axios
          .post(AXIOSLINK +'jobs', data)
          .then(response => {
            if (response.status == 200) {
              swal.fire({
                title: 'Job Posted!',
                text:
                  'Job Posted Successfully, will proccess as soon as possible..',
                icon: 'success',
                confirmButtonText: 'Okay!',
                confirmButtonColor: '#00c851',
              })
              setIsJobPosted(true)
              resetForms()
            } else {
              setIsJobPosted(false)
              swal.fire({
                title: 'Job Failed..',
                icon: 'error',
              })
            }
          })
          .catch(err => {
            // debugger
            console.log({ err })
            console.log('====================================')
            console.log(`error creating Page:${err}`)
            console.log('====================================')
            //   reject(new Error(`error on page creation:\n${err}`))
            swal.fire({
              title: 'Job Failed!',
              text: 'Job Failed.. Please try again later... ',
              icon: 'error',
              confirmButtonText: 'Okay!',
              confirmButtonColor: '#00c851',
            })
          })
      })
  }

  function resetForms() {
    setIsCaptchaVerified(false)
    setPositionTitle('')
    setApplDeadLine('')
    setStartDate(new Date())
    setLocation('')
    setPositionDesc('')
    setPositionQual('')
    setContactName('')
    setContactEmail('')
    setContactPhone('')
    setContactFax('')
    setContactVideoPhone('')
    setContactOthers('')
    setUserCaptchaText('')
    setCaptchaErrorMsg('')
    setStartDateErrorMessage('')
    setApplDeadLineErrorMessage('')
  }

  return (
    <div >
      <MDBContainer >
        <MDBCard className="newsletter-card" style={{ width: '100%' ,backgroundColor:  "rgb(249, 249, 255)"}}>
          <div className="header  ">
            <MDBRow className="d-flex justify-content-center">
              <h2 className=" mb-3 font-weight-bold" style={{fontSize:"28px"}}>
                
                {jobPostingHeaderInstr[0].jp_emp_title}
              </h2>
            </MDBRow>
          </div>
          <MDBCardBody className="mx-4 mt-4">
            <MDBRow>
              <MDBCol md="11">
                <form onSubmit={handleSubmit}>
                  <p className="h4 mb-4">
                    <ReactMarkdown
                      source={jobPostingHeaderInstr[0].jp_emp_instr}
                    />
                  </p>
                  <label htmlFor="positionTitle" className="grey-text">
                    Position Title: <span className="text-danger"> * </span>
                  </label>
                  <input
                    type="text"
                    id="positionTitle"
                    value={positionTitle}
                    className="form-control"
                    onChange={handlePositionTitle}
                  />
                  <br />
                  <label htmlFor="appDeadLine" className="grey-text">
                    The Application Deadline:
                    <span className="text-danger"> * </span>
                  </label>
                  <input
                    type="Date"
                    id="appDeadLine"
                    value={applDeadLine}
                    className="form-control"
                    onChange={handleApplDeadLine}
                  />
                  <div className="text-danger">
                    
                    {applDeadLineErrorMessage}
                  </div>
                  <br />
                  <label htmlFor="startDate" className="grey-text">
                    Start Date: <span className="text-danger"> * </span>
                  </label>
                  <input
                    type="Date"
                    id="startDate"
                    value={startDate}
                    className="form-control"
                    onChange={handleStartDate}
                  />
                  <div className="text-danger"> {startDateErrorMessage} </div>
                  <br />
                  <label htmlFor="location" className="grey-text">
                    Location: <span className="text-danger"> * </span>
                  </label>
                  <input
                    type="text"
                    id="location"
                    value={location}
                    className="form-control"
                    onChange={handleLocation}
                  />
                  <br />
                  <label htmlFor="positionDesc" className="grey-text">
                    Position Description:
                    <span className="text-danger"> * </span>
                  </label>
                  <textarea
                    type="text"
                    id="positionDesc"
                    value={positionDesc}
                    className="form-control"
                    rows="3"
                    onChange={handlePositionDesc}
                  />
                  <br />
                  <label htmlFor="positionQual" className="grey-text">
                    * Position Qualifications:
                    <span className="text-danger"> * </span>
                  </label>
                  <textarea
                    type="text"
                    id="positionQual"
                    value={positionQual}
                    className="form-control"
                    rows="3"
                    onChange={handlePositionQual}
                  />
                  <br />
                  <label htmlFor="contactName" className="grey-text">
                    Contact Name: <span className="text-danger"> * </span>
                  </label>
                  <input
                    type="text"
                    id="contactName"
                    value={contactName}
                    className="form-control"
                    onChange={handleContactName}
                  />
                  <br />
                  <label htmlFor="contactEmail" className="grey-text">
                    Contact Email: <span className="text-danger"> * </span>
                  </label>
                  <input
                    type="email"
                    id="contactEmail"
                    value={contactEmail}
                    className="form-control"
                    onChange={handleContactEmail}
                  />
                  <br />
                  <label htmlFor="contactPhone" className="grey-text">
                    Contact Phone: <span className="text-danger"> * </span>
                  </label>
                  <input
                    type="number"
                    id="contactPhone"
                    onKeyDown={e => showErr(e, 'phone')}
                    value={contactPhone}
                    className="form-control"
                    onChange={handleContactPhone}
                  />
                  <div className="text-danger"> {cPhoneErr} </div> <br />
                  <label htmlFor="contactFax" className="grey-text">
                    Contact Fax:
                  </label>
                  <input
                    type="number"
                    id="contactFax"
                    onKeyDown={e => showErr(e, 'fax')}
                    value={contactFax}
                    className="form-control"
                    onChange={handleContactFax}
                  />
                  <div className="text-danger"> {cFaxErr} </div> <br />
                  <label htmlFor="contactVideoPhone" className="grey-text">
                    Contact Video Phone:
                  </label>
                  <input
                    type="string"
                    id="contactVideoPhone"
                    value={contactVideoPhone}
                    className="form-control"
                    onChange={handleContactVideoPhone}
                  />
                  <br />
                  <label htmlFor="contactOthers" className="grey-text">
                    Contact Other:
                  </label>
                  <input
                    type="string"
                    id="contactOthers"
                    value={contactOthers}
                    className="form-control"
                    onChange={handleContactOthers}
                  />
                  <br />
                  <div>
                    <div className="row">
                      <div className="col-md-4">
                        <br />
                        <input
                          placeholder="Enter Captcha "
                          type="text"
                          id="userCaptchaText"
                          className="form-control"
                          value={userCaptchaText}
                          onChange={handleUserCaptchaText}
                        />
                      </div>
                      <div className="col-md-4">
                        <div>
                          <LoadCanvasTemplateNoReload reloadText="Reload" />
                        </div>
                      </div>
                      <div className="col-md-1 captcha-reload">
                        <i
                          className="fas fa-redo fa-lg"
                          onClick={captchaReload}
                        ></i>
                      </div>
                    </div>
                    <br />
                    <div
                      className={
                        isCaptchaVerified ? 'text-success' : 'text-danger'
                      }
                    >
                      {captchaErrorMsg}
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    {/* <MDBBtn color="success" outline type="submit">
                      Submit <MDBIcon far icon="paper-plane" className="ml-2" />
                    </MDBBtn> */}
                    <button type="submit" class="btn btn-success postjobbtn"
                    style={{backgroundColor:"rgb(0, 128, 0)",
                    borderRadius:"7px",
                    fontSize:"16px",
                    width:"22%",}}>Submit</button>
                    
                  </div>
                </form>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  )
}

export default FormPage
